import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as Integration } from '@sentry/integrations';

export default {
  init() {
    Sentry.init({
      dsn: process.env.VUE_APP_SENTRY_DSN,
      integrations: [new Integration({ Vue, logErrors: true })],
    });

    Sentry.configureScope((scope) => {
      scope.setTag('environment', process.env.NODE_ENV);
    });
  },
};
